const tableData = [
    {
        name: 'Lucinda Stickley',
        surname: 'Lucinda',
        username: 'Sirnarasa',
        country: 'Cameroun',
        date: '2018/07/29',
        since: '2018/07/29',
      }, {
        name: 'Henrietta Carsberg',
        surname: 'Henrietta',
        username: 'Paninggaran',
        country: 'Cameroun',
        since: '2019/07/13',
      }, {
        name: 'Phylys David',
        surname: 'Phylys',
        username: 'NewGlasgow',
        country: 'Cameroun',
         since: '2019/04/08',

      }, {
        name: 'Brena Shivell',
        surname: 'Phylys ',
        username: 'Caen',
        country: 'Cameroun',
        since: '2018/12/14',

      }
];

export { tableData };
