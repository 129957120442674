<template>
    <layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Basic Info</h4>
                        <div class="row mt-3">
                            <div class="col-md-3 ">
                                <div class="d-inline-flex">
                                    <label >Site Name : </label>
                                    <div class="mr-3 align-self-center h6"> {{siteData.site_name}}</div>
                                </div>
                            </div>
                            <div class="col-md-3 ">
                                <div class="d-inline-flex">
                                    <label >Region : </label>
                                    <div class="mr-3 align-self-center h6"> {{siteData.region.name}}</div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="d-inline-flex">
                                    <label >Location : </label>
                                    <div class="mr-3 align-self-center h6"> {{siteData.location}}</div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="d-inline-flex">
                                    <label >Enrollment status : </label>
                                    <div class="mr-3 align-self-center h6"> {{siteData.enrollment_status}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-md-3">
                                <div class="d-inline-flex">
                                    <label >Plateform Type : </label>
                                    <div class="mr-3 align-self-center h6"> {{siteData.platform_type}}</div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="d-inline-flex">
                                    <label >District : </label>
                                    <div class="mr-3 align-self-center h6"> {{siteData.district}}</div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="d-inline-flex">
                                    <label >Contact Person : </label>
                                    <div class="mr-3 align-self-center h6"> {{siteData.contact_person}}</div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="d-inline-flex">
                                    <label >Telephone : </label>
                                    <div class="mr-3 align-self-center h6"> {{siteData.telephone}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-md-3">
                                <div class="d-inline-flex">
                                    <label >Email : </label>
                                    <div class="mr-3 align-self-center h6"> {{siteData.email}}</div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="d-inline-flex">
                                    <label >Facility e-mail : </label>
                                    <div class="mr-3 align-self-center h6"> {{siteData.facility_email}}</div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="d-inline-flex">
                                    <label >Type of facility : </label>
                                    <div class="mr-3 align-self-center h6"> {{siteData.type_facility}}</div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="d-inline-flex">
                                    <label >Site Category : </label>
                                    <div class="mr-3 align-self-center h6"> {{siteData.site_category}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-md-3">
                                <div class="d-inline-flex">
                                    <label >Head of testing site : </label>
                                    <div class="mr-3 align-self-center h6"> {{siteData.head_of_testingsite}}</div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="d-inline-flex">
                                    <label >Phone of head : </label>
                                    <div class="mr-3 align-self-center h6"> {{siteData.phone_of_head}}</div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="d-inline-flex">
                                    <label >Email of head : </label>
                                    <div class="mr-3 align-self-center h6"> {{siteData.email_of_head}}</div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="d-inline-flex">
                                    <label >Head of facility : </label>
                                    <div class="mr-3 align-self-center h6"> {{siteData.head_of_facility}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-md-3">
                                <div class="d-inline-flex">
                                    <label >Phone of facility head : </label>
                                    <div class="mr-3 align-self-center h6"> {{siteData.phone_of_facility_head}}</div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="d-inline-flex">
                                    <label >Email of facility head : </label>
                                    <div class="mr-3 align-self-center h6"> {{siteData.email_of_facility_head}}</div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="d-inline-flex">
                                    <label >Comment: </label>
                                    <div class="mr-3 align-self-center h6"> {{siteData.comment}}</div>
                                </div>
                            </div>
                        </div>
                        <div style="display: flex; gap: 1rem;">
                            <button class="btn btn-primary mt-4 " @click.prevent="edit()">Edit</button>
                            <!--<button class="btn btn-success mt-4" @click="download()">Export Data</button>-->
                        </div>
                    </div>
                </div>
                <!-- end card -->
            </div>
        </div>
        <div style="display: flex; justify-content: space-around" v-show="false">
            <vue-html2pdf
                    :show-layout="true"
                    :float-layout="false"
                    :paginate-elements-by-height="1400"
                    :enable-download="true"
                    :preview-modal="false"
                    :filename="siteData.site_name"
                    :pdf-quality="2"
                    :manual-pagination="false"
                    :margin="0"
                    pdf-content-width="670px"
                    pdf-format="a4"
                    pdf-orientation="portrait"
                    ref="html2Pdf"

                    @progress="onProgress($event)"
                    @startPagination="startPagination()"
                    @hasPaginated="hasPaginated()"
                    @beforeDownload="beforeDownload($event)"
                    @hasDownloaded="hasDownloaded($event)"
            >
                <div class="body" slot="pdf-content">
                    <div class="container">
                        <div class="container_header">
                            <h5><b>Point-of-Care Technology Continuous Quality Improvement
                                For Early <br>Infant Diagnosis Program
                            </b></h5>
                            <h5>{{user.country_name}} ({{user.country_prefix}})</h5>
                        </div>
                        <!--<v-table>
                            <thead>
                                <tr>
                                    <th colspan="4">Participant Result Summary (Résumé des résultats du site) – </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th colspan="2" style="margin: 1rem">Site Name:</th>
                                    <th style="margin: 1rem">Date PT Received</th>
                                    <td style="margin: 1rem">Date PT Received</td>
                                </tr>
                                <tr>
                                    <th colspan="2">{{siteData.site_name}}</th>
                                    <th>Date PT Received</th>
                                    <td>Date PT Received</td>
                                </tr>
                                <tr>
                                    <th>Site ID</th>
                                    <th>Instrument</th>
                                    <th>Reagent Lot No:</th>
                                    <td>Date PT Received</td>
                                </tr>
                                <tr>
                                    <th>Site ID</th>
                                    <th>Instrument</th>
                                    <th>Reagent Lot No:</th>
                                    <td>Date PT Received</td>
                                </tr>
                            </tbody>
                        </v-table>-->
                        <div class="thead-1" style="border: 1px solid; border-collapse: collapse">
                            <h6 style="padding: 5px">Participant Result Summary (Résumé des résultats du site) –</h6>
                            <div style="display: flex; text-align: center; justify-content: space-around; border-top: 1px solid; border-collapse: collapse">
                                <div class="col-lg-6" style="border-right: 1px solid; padding: 5px; "><h6>Site Name</h6></div>
                                <div class="col-lg-3" style="border-right: 1px solid; padding: 5px; "><h6>Date PT Received</h6></div>
                                <div class="col-lg-3" style="padding: 5px; ">Date PT Received</div>
                            </div>
                            <div style="display: flex; text-align: center; justify-content: space-around; border-top: 1px solid; border-collapse: collapse">
                                <div class="col-lg-6" style="padding: 5px; border-right: 1px solid;"><h6>{{siteData.site_name}}</h6></div>
                                <div class="col-lg-3" style="padding: 5px; border-right: 1px solid;"><h6>Date PT Results Reported</h6></div>
                                <div class="col-lg-3" style="padding: 5px; ">Date PT Results Reported</div>
                            </div>
                            <div style="display: flex; text-align: center; justify-content: space-around; border-top: 1px solid; border-collapse: collapse">
                                <div class="col-lg-3" style="padding: 5px; border-right: 1px solid;"><h6>Site ID</h6></div>
                                <div class="col-lg-3" style="padding: 5px; border-right: 1px solid;"><h6>Instrument</h6></div>
                                <div class="col-lg-3" style="padding: 5px; border-right: 1px solid;"><h6>Reagent Lot No:</h6></div>
                                <div class="col-lg-3" style="padding: 5px; ">Reagent Lot No:</div>
                            </div>
                            <div style="display: flex; text-align: center; justify-content: space-around; border-top: 1px solid; border-collapse: collapse">
                                <div class="col-lg-3" style="border-right: 1px solid; padding: 5px;">{{siteData.ptid}}</div>
                                <div class="col-lg-3" style="border-right: 1px solid; padding: 5px; ">Instrument</div>
                                <div class="col-lg-3" style="border-right: 1px solid; padding: 5px; "><h6>Expiry Date</h6></div>
                                <div class="col-lg-3">Reagent Lot No:</div>
                            </div>
                        </div>
                        <div class="thead-2">
                            <h6>Site Result Summary/ résultat du site</h6>
                            <div style="display: flex; text-align: center; justify-content: space-around;">
                                <div class="col-lg-3" style="color: white"><h6></h6>Expected Result</div>
                                <div class="col-lg-2" style="border-left: 1px solid;border-right: 1px solid;border-top: 1px solid; padding: 5px;"><h6>Site Name</h6></div>
                                <div class="col-lg-2" style="border-right: 1px solid; border-top: 1px solid;padding: 5px;"><h6>Date PT Received</h6></div>
                                <div class="col-lg-2" style="border-right: 1px solid; border-top: 1px solid;padding: 5px;"><h6>Score</h6></div>
                                <div class="col-lg-3" style="padding: 5px;border-right: 1px solid; border-top: 1px solid;"><h6>Score</h6></div>
                            </div>
                            <div style="display: flex; flex-direction: column; text-align: center; justify-content: space-around; border: 1px solid; border-collapse: collapse">
                                <div style="display: flex; text-align: center; justify-content: space-around;">
                                    <div class="col-lg-3" style="border-right: 1px solid; padding: 5px;"><h6>Expected Result</h6></div>
                                    <div class="col-lg-2" style="border-right: 1px solid; padding: 5px;"><h6>Site Name</h6></div>
                                    <div class="col-lg-2" style="border-right: 1px solid; padding: 5px;"><h6>Date PT Results Reported</h6></div>
                                    <div class="col-lg-2" style="border-right: 1px solid; padding: 5px;">Date PT Results Reported</div>
                                    <div class="col-lg-3" style="padding: 5px;">Date PT Results Reported</div>
                                </div>
                                <div style="display: flex; text-align: center; justify-content: space-around; border-collapse: collapse">
                                    <div class="col-lg-3" style="border-right: 1px solid;border-top: 1px solid; "><h6>Your Result</h6></div>
                                    <div class="col-lg-2" style="border-right: 1px solid;border-top: 1px solid; "><h6>Instrument</h6></div>
                                    <div class="col-lg-2" style="border-right: 1px solid;border-top: 1px solid; "><h6>Reagent Lot No:</h6></div>
                                    <div class="col-lg-2" style="border-right: 1px solid;border-top: 1px solid; ">Reagent Lot No:</div>
                                    <div class="col-lg-3" style="color: white">Reagent Lot No:</div>
                                </div>
                            </div>
                        </div>
                        <div class="thead-3">
                            <h6>Summary of All Site Scores/ Résumé des résultats de tous les sites</h6>
                            <div style="display: flex; flex-direction: column; border: 1px solid; border-collapse: collapse">
                                <div style="display: flex;">
                                    <div class="col-lg-3" style="padding: 5px; border-right: 1px solid;"><h6><b>Total number of Participating Sites</b></h6></div>
                                    <div class="col-lg-3" style="padding: 5px; border-right: 1px solid;"><h6><b>Total number of Sites Scoring 100%</b></h6></div>
                                    <div class="col-lg-3" style="padding: 5px; border-right: 1px solid;"><h6><b>Total number of Sites Scoring below 100%</b></h6></div>
                                    <div class="col-lg-3" style="padding: 5px;"><h6><b>Percentage of Sites Scoring 100%</b></h6></div>
                                </div>
                                <div  style="display: flex; border-top: 1px solid">
                                    <div class="col-lg-3" style="padding: 5px; border-right: 1px solid;"><h6>Summary of All Site Scores/ Résumé des résultats de tous les sites</h6></div>
                                    <div class="col-lg-3" style="padding: 5px; border-right: 1px solid;"><h6>Total number of Sites Scoring 100%</h6></div>
                                    <div class="col-lg-3" style="padding: 5px; border-right: 1px solid;"><h6>Total number of Sites Scoring below 100%</h6></div>
                                    <div class="col-lg-3" style="padding: 5px;"><h6>Percentage of Sites Scoring 100%</h6></div>
                                </div>
                            </div>
                        </div>
                        <div class="thead-4">
                            <h6>Percentage of all Participating Sites reporting correctly</h6>
                            <h6>Pourcentage de tous les sites participants qui établissent correctement leurs rapports</h6>
                            <div style="display: flex">
                                <div class="col-lg-3" style="color: white">Correctly Reported</div>
                                <div class="col-lg-2" style="padding: 5px; border-top: 1px solid; border-left: 1px solid;border-right: 1px solid;"><h6><b>A</b></h6></div>
                                <div class="col-lg-2" style="padding: 5px; border-top: 1px solid;border-right: 1px solid;"><h6><b>A</b></h6></div>
                                <div class="col-lg-2" style="padding: 5px; border-top: 1px solid;border-right: 1px solid;"><h6><b>A</b></h6></div>
                                <div class="col-lg-3" style="padding: 5px; border-top: 1px solid;border-right: 1px solid;"><h6><b>Average</b></h6></div>
                            </div>
                            <div style="display: flex; border: 1px solid;">
                                <div class="col-lg-3" style="padding: 5px;border-right: 1px solid;"><b>Correctly Reported</b></div>
                                <div class="col-lg-2" style="padding: 5px;border-right: 1px solid;"><b>V</b></div>
                                <div class="col-lg-2" style="padding: 5px;border-right: 1px solid;"><b>V</b></div>
                                <div class="col-lg-2" style="padding: 5px;border-right: 1px solid;"><b>V</b></div>
                                <div class="col-lg-3" style="padding: 5px;"> <b>V</b></div>
                            </div>
                        </div>
                        <div class="thead-5">
                            <h5>OPERATOR’S NAME</h5>
                            <h5>Name</h5>
                        </div>

                    </div>
                </div>
            </vue-html2pdf>
        </div>
    </layout>


</template>

<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";

    import {
        required,
        email,
        minLength,
        sameAs,
        maxLength,
        minValue,
        maxValue,
        numeric,
        url,
        alphaNum
    } from "vuelidate/lib/validators";
    import {tableData} from "../site/dataAdminEID";
    import {axios_get} from "../../../helpers/helper";

    /**
     * Form validation component
     */
    export default {
        page: {
            title: "View EID Site",
            meta: [{ name: "description", content: appConfig.description }]
        },
        components: { Layout,PageHeader },
        data() {
            return {
                title: "View EID Site",
                items: [
                    {
                        text: "EID Site",
                        href: "/sites/eid"
                    },
                    {
                        text: "View EID Site",
                        active: true
                    }
                ],
                tableData: tableData,
                siteData:null,
                downloadInfo:false,
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortBy: "",
                sortDesc: false,
                fields: [
                    { key: "reference", sortable: true },
                    { key: "session", sortable: true },
                    { key: "month", sortable: true },
                    { key: "year", sortable: true },
                    { key: "state", sortable: true },
                ],
                fieldsPT: [
                    { key: "name", sortable: true },
                    { key: "surname", sortable: true },
                    { key: "username", sortable: true },
                    { key: "country", sortable: true },
                    { key: "since", sortable: true },
                    { key: "actions", sortable: true },
                ],

                form: {
                    fname: "",
                    lname: "",
                    city: "",
                    state: "",
                    zipcode: ""
                },
                tooltipform: {
                    fname: "",
                    lname: "",
                    zipcode: "",
                    city: "",
                    state: ""
                },
                range: {
                    minlen: "",
                    maxlength: "",
                    between: "",
                    minval: "",
                    maxval: "",
                    rangeval: "",
                    expr: ""
                },
                typeform: {
                    name: "",
                    password: "",
                    confirmPassword: "",
                    email: "",
                    url: "",
                    digit: "",
                    number: "",
                    alphanum: "",
                    textarea: ""
                },
                options: [
                    "Adamawa ",
                    "Centre",
                    "East",
                    "Far North",
                    "Littoral",
                    "North",
                    "North West",
                    "West",
                    "South",
                    "South West",
                ],
                options1: [
                    "Confessional ",
                    "Faith based",
                    "Parastatal",
                    "Public",
                    "NGO",
                ],
                options2: [
                    "EID ",
                    "VL",
                ],
                submitted: false,
                submitform: false,
                submit: false,
                typesubmit: false,
                value1: null,
                user: JSON.parse(localStorage.getItem('user')).country,
                value2: null,
            };

        },
        validations: {
            form: {
                fname: { required },
                lname: { required },
                city: { required },
                state: { required },
                zipcode: { required }
            },
            tooltipform: {
                fname: { required },
                lname: { required },
                username: { required },
                city: { required },
                state: { required }
            },
            range: {
                minlen: { required, minLength: minLength(6) },
                maxlength: { required, maxLength: maxLength(6) },
                between: { required, minLength: minLength(5), maxLength: maxLength(10) },
                minval: { required, minValue: minValue(6) },
                maxval: { required, maxValue: maxValue(6) },
                rangeval: { required, minValue: minValue(6), maxValue: maxValue(100) },
                expr: { required }
            },
            typeform: {
                name: { required },
                password: { required, minLength: minLength(6) },
                confirmPassword: { required, sameAsPassword: sameAs("password") },
                email: { required, email },
                url: { required, url },
                digit: { required, numeric },
                number: { required, numeric },
                alphanum: { required, alphaNum },
                textarea: { required }
            }
        },
        methods: {
            // eslint-disable-next-line no-unused-vars
            download(){
                console.log("dowload")
                this.$refs['html2Pdf'].generatePdf()
            },

            async beforeDownload ({ html2pdf, options=this.setOptions, pdfContent }) {
                await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
                    console.log(pdf)
                    const totalPages = pdf.internal.getNumberOfPages()
                    for (let i = 1; i <= totalPages; i++) {
                        //pdf.setPage(i)
                        //pdf.setFontSize(10)
                        //pdf.setTextColor(150)
                        //pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
                    }
                }).save()
            },

            /**
             * Range validation form submit
             */
            // eslint-disable-next-line no-unused-vars
            rangeform(e) {
                this.submit = true;
                // stop here if form is invalid
                this.$v.$touch();
            },
            /**
             * Validation type submit
             */
            // eslint-disable-next-line no-unused-vars
            typeForm(e) {
                this.typesubmit = true;
                // stop here if form is invalid
                this.$v.$touch();
            },
            edit(){
                window.location.href = '/site/eid/edit-site/'+this.siteData.id;
            }

        },
        mounted() {
            console.log(this.$route.params.id)
            this.id=this.$route.params.id
            console.log(this.id),
                axios_get("/sites/"+this.id,{},(d)=>{
                        console.log(d);
                        this.siteData=d[0];
                        this.siteData.enrollment_status= new Date(this.siteData.enrollment_status).getFullYear();
                        console.log(this.siteData);
                    },(e)=>{
                        console.log("error",e);
                    },()=>{
                        console.log("finishing");
                    }
                );
        }
    };
</script>

<style scoped>
    .container_header{
        text-align: center;
    }
    tbody, thead{
        width: 500px;
    }
    tr{
        text-align: center;
        font-size: 18px;
        margin: 1rem;
    }
    .thead-1,.thead-2,.thead-3,.thead-4,.thead-5{
        margin-top: 2rem;
        margin-bottom: 1rem;
        text-align: center;
    }
</style>